<template>
  <div class="readme-article">
    <h1 id="学生关怀">
      三、学生关怀
    </h1>
    <h2 id="预约记录">1.预约记录</h2>
    <p>查看回访预约记录，内容包括学生姓名，预约回访时间，预约备注等。</p>
    <p>
      <img src="@/assets/img/student/3-1.png">
    </p>
    <h2 id="回访记录">2.回访记录</h2>
    <p>查看回访记录，内容包括学生姓名，预约回访时间，备注内容等。</p>
    <p>
      <img src="@/assets/img/student/3-2.png">
    </p>
    <h2 id="学生回访查询">3.学生回访查询</h2>
    <p>查看学生回访记录，支持条件查询。</p>
    <p>
      <img src="@/assets/img/student/3-3.png">
    </p>
    <h2 id="N天未回访">4.N天未回访</h2>
    <p>查看N天未回访学生列表，支持条件筛选学员。</p>
    <p>
      <img src="@/assets/img/student/3-4.png">
    </p>
    <h2 id="近期生日的学生">5.近期生日的学生</h2>
    <p>查看近期生日学生列表，支持条件查询。</p>
    <p>
      <img src="@/assets/img/student/3-5.png">
    </p>
    <h2 id="家长会查询">6.家长会查询</h2>
    <p>查看家长会列表，内容包括参会人员、开会时间，会议内容等。</p>
    <p>
      <img src="@/assets/img/student/3-6.png">
    </p>
    <h2 id="成绩查询">7.成绩查询</h2>
    <h3 id="成绩查询列表">7.1成绩查询列表</h3>
    <p>查看学员成绩记录，内容包括学生信息、考试科目、考试类型、分数等</p>
    <p>
      <img src="@/assets/img/student/3-7.png">
    </p>
    <h3 id="成绩详情">7.2成绩详情</h3>
    <p>学生成绩详情展示。</p>
    <p>
      <img src="@/assets/img/student/3-8.png">
    </p>
    <h2 id="请假记录查询">8.请假记录查询</h2>
    <p>查看学生请假记录，支持条件查询。</p>
    <p>
      <img src="@/assets/img/student/3-9.png">
    </p>
    <h2 id="学员通话记录">9.学员通话记录</h2>
    <p>查看学员通话记录，包括通话时间，通话时长等信息，可播放录音。</p>
    <p>
      <img src="@/assets/img/student/3-10.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student3-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>